<route>
{
  "meta": {
    "permission": [
      "inventory.view_movement"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" sm="3">
        <ValidationProvider vid="code" :name="$t('code')" v-slot="{ errors }">
          <v-select
            autocomplete="off"
            outlined
            :error-messages="errors[0]"
            v-model="form.code"
            :item-text="(item) => `${item.internalCode} ${item.name}`"
            item-value="pk"
            :items="products"
            :label="$t('code')"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="movement_type"
          :name="$t('movement_type')"
          v-slot="{ errors }"
        >
          <v-select
            autocomplete="off"
            outlined
            :error-messages="errors[0]"
            v-model="form.movement_type"
            :items="movementTypes"
            :label="$t('movement_type')"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn color="secondary" @click="clear()">
          {{ $t('clear') }}
        </v-btn>
      </v-col>
    </v-row>
    <i-table
      :title="$t('movementsReport')"
      :headers="headers"
      app="inventory.movement"
      api="movement"
      :opt="{
        code: this.form.code,
        movement_type: this.form.movement_type,
        company_id: company.pk
      }"
      dontEdit
      dontRemove
      dontCreate
      dontSearch
    >
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-excel"
          :title="$t('download', { value: $t('movementsReport') })"
          color="text-dark"
          @click="getMovementsExcel()"
        >
        </i-btn>
      </template>
    </i-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      form: {
        code: '',
        movement_type: ''
      },
      products: [],
      movementTypes: []
    }
  },
  mounted() {
    this.getProducts()
    this.getMovementType()
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('code', 2),
          value: 'own_prod'
        },
        {
          text: this.$tc('movement_type', 2),
          value: 'movement_type'
        },
        {
          text: this.$tc('quantity', 2),
          value: 'quantity'
        },
        {
          text: this.$t('updated_date'),
          value: 'updated_date'
        },
        {
          text: this.$t('updated_user'),
          value: 'own_user'
        }
      ]
    },
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  },
  methods: {
    getMovementsExcel() {
      this.downloadFile({
        api: 'movement',
        method: 'excel',
        filename: 'InventoryMovements',
        action: 'download',
        ext: 'xlsx',
        opt: {
          pk: 1,
          params:
            '?code=' +
            this.form.code +
            '&movement_type=' +
            this.form.movement_type +
            '&company_id=' +
            this.company.pk
        }
      })
    },
    getProducts() {
      this.$api.product.list({}).then((response) => {
        this.products = response.data.results
      })
    },
    getMovementType() {
      this.$api.movement.movementType().then((response) => {
        this.movementTypes = response.data
      })
    },
    clear() {
      this.form = {
        code: '',
        movement_type: ''
      }
    }
  }
}
</script>
